<template>
  <div class="OrderDetail">
    <div class="row justify-center">
      <div class="col-auto">
        <q-btn-group
          :spread="isMobile"
          :class="{ 'fixed-bottom': isMobile, 'q-mt-md': !isMobile }"
          style="z-index:999;"
        >
          <q-btn icon="exit_to_app" class="buttonsBar" @click="exit" />
          <q-btn
            icon="account_tree"
            class="buttonsBar"
            @click="openHierarchy"
          />
          <q-btn icon="business" class="buttonsBar" @click="openHistoric" />
          <q-btn
            icon="table_rows"
            class="buttonsBar"
            v-if="viewTable"
            @click="viewTable = !viewTable"
          />
          <q-btn
            icon="table_chart"
            class="buttonsBar"
            v-if="!viewTable"
            @click="viewTable = !viewTable"
          />
        </q-btn-group>
      </div>
    </div>

    <q-scroll-area style="height: 80vh;">
      <div v-if="viewTable">
        <q-card class="q-ma-sm" v-if="order">
          <q-card-section>
            <div class="row justify-start">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Pedido: </span>
                <span>{{ order.orderNumber }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Empresa: </span>
                <span>{{ `${order.branchCode} - ${order.branchName}` }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Municipio: </span>
                <span>{{
                  `${order.branchCity || ""} - ${order.branchUf || ""}`
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Forn.: </span>
                <span>{{ order.supplierName }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Municipio Forn.: </span>
                <span>{{
                  `${order.supplierCity || ""} - ${order.supplierUf || ""}`
                }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Data da SC: </span>
                <span>{{
                  `${formatString(order.requestDate || "", "date")}`
                }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Dt. Pedido: </span>
                <span>{{ formatString(order.issueDate, "date") }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Solicitante: </span>
                <span>{{ order.requester }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Comprador: </span>
                <span>{{ order.buyer }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Centro de Custo: </span>
                <span>{{ order.costCenter }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Condição Pagto: </span>
                <span>{{ order.faymentForm }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Tipo Frete: </span>
                <span>{{ order.freightType }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Valor Frete: </span>
                <span>{{
                  formatString(
                    order.freightAmount || 0,
                    "currency",
                    null,
                    order.currency
                  )
                }}</span>
              </div>

              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Valor : </span>
                <span>{{
                  formatString(
                    order.netAmount || 0,
                    "currency",
                    null,
                    order.currency
                  )
                }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Desconto: </span>
                <span>{{
                  formatString(
                    order.amountDiscount || 0,
                    "currency",
                    null,
                    order.currency
                  )
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Valor Total: </span>
                <span>{{
                  formatString(
                    order.amountOfOrder,
                    "currency",
                    null,
                    order.currency
                  )
                }}</span>
              </div>
            </div>
          </q-card-section>
        </q-card>
        <DataTable
          v-if="order"
          :data="order.items"
          :columns="columns"
          :gridMobile="false"
          hidePagination
          class="q-mx-sm"
          style="margin-bottom: 50px ;"
          :currency="order.currency"
        />
      </div>
      <div v-else>
        <productsCard :data="order" />
      </div>
    </q-scroll-area>
    <!-- Referente ao informativo APROVADO, PENDENTE ou REJEITADO -->
    <div class="row justify-evenly q-mb-xl">
      <q-banner
        v-if="status === 'Aprovado'"
        inline-actions
        class="text-white text-center bg-green"
        style="width: 80%"
      >
        APROVADO
      </q-banner>
      <q-banner
        v-if="status === 'Pendente'"
        inline-actions
        class="text-white text-center bg-orange"
        style="width: 80%"
      >
        PENDENTE
      </q-banner>
      <q-banner
        v-if="status === 'Reprovado'"
        inline-actions
        class="text-white text-center bg-red"
        style="width: 80%"
      >
        REPROVADO
      </q-banner>
    </div>
    <ApprovalHierarchy ref="ApprovalHierarchy" />
    <PurchasesHistoric ref="PurchasesHistoric" />
  </div>
</template>

<script>
import DataTable from "@/components/DataTable";
import ApprovalHierarchy from "./ApprovalHierarchy";
import PurchasesHistoric from "./PurchasesHistoric";
import productsCard from "./productsCard";
import formatString from "@/helpers/formatString";
import { mapActions } from "vuex";

export default {
  components: {
    DataTable,
    ApprovalHierarchy,
    productsCard,
    PurchasesHistoric
  },
  data() {
    return {
      order: null,
      viewTable: true,
      columns: [
        {
          field: "item",
          label: "Item",
          align: "center",
          sortable: true
        },
        {
          field: "productDescription",
          label: "Produto",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "um",
          label: "UM",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "quantity",
          label: "Qt.",
          align: "right",
          type: "number",
          sortable: true
        },
        {
          field: "unitaryValue",
          label: "Vlr.Unit.",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "unitaryDiscount",
          label: "Desc.Unit.",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "amount",
          label: "Vlr.Tot.",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "ipi",
          label: "IPI",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "valueLastPurchase",
          label: "Valor Últ. Entrada",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "deliveryDate",
          label: "Dt. Entrega",
          align: "center",
          type: "date",
          sortable: true
        }
      ],
      status: null
    };
  },
  computed: {
    historyID() {
      return this.$route.params.id;
    },
    isMobile() {
      return this.$q.screen.xs;
    }
  },
  methods: {
    ...mapActions("historyOrders", ["getHistory"]),
    formatString,
    exit() {
      this.$router.back();
    },
    openHierarchy() {
      this.$refs.ApprovalHierarchy.open(this.order.approvalHierarchy);
    },
    openHistoric() {
      this.$refs.PurchasesHistoric.open(this.order);
    }
  },
  async created() {
    let filter = this.$route.params.order;
    let response = await this.getHistory(filter);

    response = response.filter(resp => {
      return resp.cnpj.indexOf(this.historyID) >= 0;
    });

    this.order = response[0];
  }
};
</script>
<style lang="scss">
.OrderDetail {
  width: 100%;
  .buttonsBar {
    background: #11275b;
    color: #fff;
  }
}
</style>
